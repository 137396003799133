import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormData } from '../context/FormState/FormDataContext';

function SubmitButton(props: { onClick: () => void }) {
  const { t } = useTranslation();
  const { state: formState } = useFormData();

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={!!!formState.valid}
      onClick={props.onClick}
      // onClick={async () => {
      //   console.log('submitting form');
      //   await trackPromise(submitInternalForm(formState.formDefinition.fields));
      // }}
    >
      {t('Submit')}
    </Button>
  );
}

export default SubmitButton;
