import { CssBaseline } from '@material-ui/core';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import { create } from 'jss';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Landing from './Landing';
import themeMfp from './components/mfp/theme';
import themeStratus from './components/embed/theme';
import {
  MessageBoxContextProvider,
  CustomMessageBox,
  DisableContextAndShortcuts,
} from '@dispatcher-stratus/stratus-react';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Mfp } from './components/mfp';
import { FormDataCollection } from './components/FormDataCollection';
import { FormDataContextProvider } from './components/context/FormState/FormDataContext';
import './__mocks__/form';
import './__mocks__/session';
import Login from './components/Login';
import { AppContextProvider } from './components/context/AppState/AppContext';
import { Form } from './components/Form';
import Success from './components/success/Success';
import Unavailable from './components/unavailable/Unavailable';
import { JobTicketForm } from './components/JobTicketForm';
import { AuthContextProvider } from './components/context/AuthState/AuthContext';
import { AuthState } from './interfaces/AuthState';
import { FormEdit } from './components/EditForm';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });

  //match user agent against known ChromiumBrowser user agents user on MFP:
  //ChromiumBrowser/1.0
  //ChromiumBrowser/1.1
  //ChromiumBrowser/2.0
  //ChromiumBrowser/2.1
  const isMFP = /(ChromiumBrowser\/[1-2].[0-1])+/.test(navigator.userAgent);

  const authState: AuthState = {
    // isAuthenticated: true,
    // token:
    //   'eyJraWQiOiJQcnhsREpRdjA1blR4RncyRWh2N0lkK2FUbGVMMlEzdXRVYlJhanRpcDFVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwMmY4YTI3Mi02NzAxLTQyOTgtYTMyNy02YjZhZmFhNTJiOTciLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfRUd1N3NsZUsyX0tvbmljYU1pbm9sdGFNYXJrZXRQbGFjZSJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9FR3U3c2xlSzIiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiI0a2htdDZzdGFxMnBxdGZmcnMxbmw4MjVqZyIsIm9yaWdpbl9qdGkiOiIzNWE4NGU4My04NzllLTQ3MjItYjAzNy1lNTNhNDNlNWQ5MWYiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNzE1MDA0Nzg5LCJleHAiOjE3MTUzNTA0MDIsImlhdCI6MTcxNTI2NDAwMiwianRpIjoiYWY1MDEyNzUtZjYxNy00ZmMyLWFiMWYtNjA5YTZlNDhiZTMwIiwidXNlcm5hbWUiOiJrb25pY2FtaW5vbHRhbWFya2V0cGxhY2VfNjBhNTA3ZjVmZjgyYTg1ZWZjNWUxYzJkYmQ0YjkwOTgxZjY4ZWNkZCJ9.iQJntaI0e9_Imb5WwJmIp094iQYDoDbc92vm5N3GElTq14vtNKvAbs5pJEFBElrwqsfNv5odHKDdlwhKsE3_cQyqplamylIc2-KNNHjB1vpcvC-_JYk_Tb1AARgXFW_LTZCJd4CP00OI0GvZdB1mu3hzG1N91pNy2MahVTwghyLIL8mIULtVgLjL4c15sCupYoyvSiQFT6TPAUyMJzGTR_oP_4pNkPmabgzV_dMcsK44spmTjwq0H0USq4gBp-ptv0C6cCaC3TLqFKK3B1Nyf6Qpwwk3qANHwPdGXs2sXwznrfldGAjIlCnHPY0oPc4AWp0X7aA96b_TW2I8StUVIA',
    // fetched: true,
    // region: 'us-east-1',
    isAuthenticated: false,
    token: undefined,
    fetched: false,
    region: undefined,
  };
  const routing = (
    <I18nextProvider i18n={i18n}>
      <AppContextProvider>
        <AuthContextProvider initialState={authState}>
          <MessageBoxContextProvider>
            <Router>
              <Switch>
                <Route path="/client/mfp">
                  <ThemeProvider theme={themeMfp}>
                    <CssBaseline />
                    <FormDataContextProvider>
                      <Mfp />
                      <CustomMessageBox i18n={i18n}></CustomMessageBox>
                    </FormDataContextProvider>
                  </ThemeProvider>
                </Route>
                <Route path="/externalform/:region/:slug/:urlId">
                  <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                    <CssBaseline />
                    <FormDataContextProvider>
                      <FormDataCollection>
                        <Form />
                      </FormDataCollection>
                      <CustomMessageBox i18n={i18n}></CustomMessageBox>
                    </FormDataContextProvider>
                  </ThemeProvider>
                </Route>
                <Route path="/internalform/:region/:slug/:urlId">
                  <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                    <CssBaseline />
                    <FormDataContextProvider>
                      <JobTicketForm>
                        <Form />
                      </JobTicketForm>
                      <CustomMessageBox i18n={i18n}></CustomMessageBox>
                    </FormDataContextProvider>
                  </ThemeProvider>
                </Route>
                <Route path="/editform/:region/:slug/:workflowId/:processId/:formId">
                  <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                    <CssBaseline />
                    <FormDataContextProvider>
                      <FormEdit>
                        <Form />
                      </FormEdit>
                      <CustomMessageBox i18n={i18n}></CustomMessageBox>
                    </FormDataContextProvider>
                  </ThemeProvider>
                </Route>
                <Route path="/sso/login">
                  <CssBaseline />
                  <Login />
                </Route>
                <Route path="/client">
                  <Landing></Landing>
                </Route>
                <Route path="/success">
                  <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                    <CssBaseline />
                    <FormDataContextProvider>
                      <Success />
                    </FormDataContextProvider>
                  </ThemeProvider>
                </Route>
                <Route path="/unavailable">
                  <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                    <CssBaseline />
                    <Unavailable />
                  </ThemeProvider>
                </Route>
                <Redirect from="/" to="/client" />
              </Switch>
            </Router>
          </MessageBoxContextProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </I18nextProvider>
  );
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider jss={jss}>
        <DisableContextAndShortcuts />
        <div className="App flex flex-col h-screen v-screen bg-black">
          {routing}
        </div>
      </StylesProvider>
    </QueryClientProvider>
  );
}
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')!,
});
export default App;
