import { FC, useEffect, memo } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { TextField as TextFieldType } from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { Typography } from '@material-ui/core';

interface Props {
  field: TextFieldType;
}

const TextAreaField: FC<any> = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  useEffect(() => {
    if (field.config.readonly) return;
    function validateField(): string {
      const { required, minLength, maxLength } = field.config;
      if (maxLength && field.value.length > field.config.maxLength)
        return t('error valueLong', {
          max: field.config.maxLength.toString(),
        });
      if (minLength && field.value.length < field.config.minLength)
        return t('error valueShort', {
          min: field.config.minLength.toString(),
        });
      if (required && !field.value) return t('error empty');
      return '';
    }
    if (field.config.readonly) return; //skip valiation if readonly
    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field, dispatch]);

  function handleChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.value,
      },
    });
  }

  return (
    <TextField
      label={
        <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
          {field.config.title}
          {field.config.required ? ' *' : ''}
        </Typography>
      }
      fullWidth
      multiline
      maxRows={4}
      minRows={4}
      key={field.id + '_field'}
      variant="outlined"
      size="small"
      value={field.value}
      onChange={handleChange}
      error={!!fieldError}
      disabled={field.config.readonly}
      helperText={fieldError}
      inputProps={{
        maxLength: 255,
      }}
    />
  );
};

export default memo(TextAreaField);
