import { axiosMockAdapterInstance as mock } from '../lib/axios';

console.log('mocking form...');

mock.onGet('api/forms/1').reply(() => {
  const form = {
    id: '31401327af274e8fbc475eee2e4d9189',
    title: 'Full Form Example',
    creator: 'user 1',
    editor: 'user 1',
    created: 1690810009418,
    updated: 1690810009418,
    formStatus: 'published',
    valid: true,
    formGroup: {
      id: '00000',
    },
    formDefinition: {
      targetMfpId: 'mfp_800x480',
      fields: [
        {
          type: 'pagebreak',
          id: 'vn01PYmrf6L-o0O9-yDD8',
          config: {
            variable: 'page1',
            title: 'My First Page',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: true,
            helpText: 'hello world',
          },
        },
        {
          type: 'text',
          id: 'lO5CFI-2cUnyK4Y4bPWC0',
          config: {
            variable: 'text1',
            title: 'Text Line field friendly name goes here',
            defaultValue: 'Hello',
            minLength: '1',
            maxLength: '50',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'textarea',
          id: '5AJqeHry7VHJ3XTnmMhDc',
          config: {
            variable: 'textarea1',
            title: 'Text Area field friendly name goes here',
            defaultValue: 'World',
            minLength: '1',
            maxLength: '100',
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'number',
          id: 'MF-bG6wUOYhBqeYR3yrKk',
          config: {
            variable: 'number1',
            title: 'Number field friendly name goes here',
            defaultValue: '1234',
            minLength: '1',
            maxLength: '7',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'password',
          id: 'mXQkf-dh1PlxzKIX6sEKl',
          config: {
            variable: 'password1',
            title: 'Password field friendly name goes here',
            minLength: '',
            maxLength: '',
            required: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'checkbox',
          id: 'sJ-N9t2hnspIO08w9RI83',
          config: {
            variable: 'checkbox1',
            title: 'Checkmark field friendly name goes here',
            defaultValue: '',
            trueLabel: 'Yes',
            falseLabel: 'No',
            readonly: false,
            checked: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'dropdown',
          id: 'FGhLBdcvv4xRq9fZQ0wVF',
          config: {
            variable: 'dropdown1',
            title: 'Dropdown field friendly name goes here',
            defaultValue: '',
            minLength: '',
            maxLength: '',
            required: true,
            readonly: false,
            multiSelect: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
            options: [
              {
                id: '5oOhAtDIj1A6P6U8UWGfY',
                label: 'User1',
                value: 'user1@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: '12l_4npZrrNVbBIL3cHv8',
                label: 'User2',
                value: 'user2@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: 'SCwAEMFdeM6vrsr73DNdN',
                label: 'User3',
                value: 'user3@domain.com',
                show: true,
                isDefault: true,
              },
            ],
          },
        },
        {
          type: 'date',
          id: 'h3ew-M3oAOxbcg7ji-_M-',
          config: {
            variable: 'date1',
            title: 'Date and Time field friendly name',
            defaultValue: '',
            minValue: '08/01/2023 10:00 am',
            maxValue: '09/05/2023 12:30 pm',
            returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
            defaultToNow: false,
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'label',
          id: 'fRT-OO3Kw5Y1dRsT0fHCC',
          config: {
            variable: 'label1',
            title: 'Label goes here',
          },
        },
        {
          type: 'hidden',
          id: 'uoXzu4Vs2Z7wJHHYUvE4c',
          config: {
            variable: 'hidden1',
            defaultValue: '3232',
            excludeFromMetadata: false,
          },
        },
        {
          type: 'pagebreak',
          id: '3oy5KXuT_0o6Uf4q2UpTP',
          config: {
            variable: 'page2',
            title: 'My Second Page ',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: true,
            helpText:
              'really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text really realy long text rea',
          },
        },
        {
          type: 'text',
          id: 'JOq-0uiIKB2LwMUpaRvIB',
          config: {
            variable: 'text2',
            title: 'Text Line field friendly name goes here',
            defaultValue: 'Hello',
            minLength: '1',
            maxLength: '50',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'textarea',
          id: 'uM6L8wNZYpD41mjYpYDCK',
          config: {
            variable: 'textarea2',
            title: 'Text Area field friendly name goes here',
            defaultValue: 'line 1 test here\nline 2 test here\nline 3 test here\nline 4 test here',
            minLength: '1',
            maxLength: '100',
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'number',
          id: 'wJXEz3-MdaKP4TUAeAIz8',
          config: {
            variable: 'number2',
            title: 'Number field friendly name goes here',
            defaultValue: '1234',
            minLength: '1',
            maxLength: '7',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'password',
          id: 'zD1L8JkvVE2orZ7BMJ_MQ',
          config: {
            variable: 'password2',
            title: 'Password field friendly name goes here',
            minLength: '',
            maxLength: '',
            required: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'checkbox',
          id: 'kpYTyQ7vB3pydm6dIWxoD',
          config: {
            variable: 'checkbox2',
            title: 'Checkmark field friendly name goes here',
            defaultValue: '',
            trueLabel: 'Yes',
            falseLabel: 'No',
            readonly: false,
            checked: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'dropdown',
          id: '_j7j5JO_i2A5B1ZK_vD2M',
          config: {
            variable: 'dropdown2',
            title: 'Dropdown field friendly name goes here',
            defaultValue: '',
            minLength: '',
            maxLength: '',
            required: true,
            readonly: false,
            multiSelect: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
            options: [
              {
                id: '5oOhAtDIj1A6P6U8UWGfY',
                label: 'User1',
                value: 'user1@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: '12l_4npZrrNVbBIL3cHv8',
                label: 'User2',
                value: 'user2@domain.com',
                show: true,
                isDefault: false,
              },
              {
                id: 'SCwAEMFdeM6vrsr73DNdN',
                label: 'User3',
                value: 'user3@domain.com',
                show: true,
                isDefault: false,
              },
            ],
          },
        },
        {
          type: 'date',
          id: 'DQqLuXZ_Xan1tkjyKv0tX',
          config: {
            variable: 'date2',
            title: 'Date and Time field friendly name goes here',
            defaultValue: '7/31/2023, 9:16:05 AM',
            minValue: '',
            maxValue: '',
            returnFormat: '',
            defaultToNow: false,
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'label',
          id: 'vYMQFYywY2DVzfUJZIjwL',
          config: {
            variable: 'label2',
            title: 'Label goes here',
          },
        },
        {
          type: 'hidden',
          id: '-bXzMX5FeGimBvdznhG7Q',
          config: {
            variable: 'hidden2',
            defaultValue: '4444',
            excludeFromMetadata: false,
          },
        },
      ],
    },
  };
  return [200, form];
});

mock.onGet('api/forms/2').reply(() => {
  const form = {
    id: '31401327af274e8fbc475eee2e4d9189',
    title: 'Full Form Example',
    creator: 'user 1',
    editor: 'user 1',
    created: 1690810009418,
    updated: 1690810009418,
    formStatus: 'published',
    valid: true,
    formGroup: {
      id: '00000',
    },
    formDefinition: {
      targetMfpId: 'mfp_800x480',
      fields: [
        {
          type: 'pagebreak',
          id: 'vn01PYmrf6L-o0O9-yDD8',
          config: {
            variable: 'page1',
            title: 'My First Page',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: true,
            helpText: 'hello world',
          },
        },
        {
          type: 'text',
          id: 'lO5CFI-2cUnyK4Y4bPWC0',
          config: {
            variable: 'text1',
            title: 'Text Line field friendly name goes here',
            defaultValue: 'Hello',
            minLength: '1',
            maxLength: '50',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'date',
          id: 'h3ew-M3oAOxbcg7ji-_M-',
          config: {
            variable: 'date1',
            title: 'Date and Time field friendly name',
            defaultValue: '',
            minValue: '2023-08-01T14:00:00.000Z',
            maxValue: '2023-08-05T16:30:00.000Z',
            returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
            defaultToNow: false,
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
        {
          type: 'date',
          id: 'h3ew-M3oAOxbcg7ji-_M-1',
          config: {
            variable: 'date1-ISO',
            title: 'Date and Time field friendly name',
            defaultValue: '',
            minValue: '08/01/2023 10:00 am',
            maxValue: '09/05/2023 12:30 pm',
            returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
            defaultToNow: false,
            required: true,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: true,
            helpText: 'Help text goes here.',
          },
        },
      ],
    },
  };
  return [200, form];
});

mock.onGet('api/forms/3').reply(() => {
  const form = {
    id: '38c9af3e02ba4cbda75e80a7dcb68630',
    title: 'Nick 1 - Copy of Sara 1 - Copy',
    creator: 'konicaminoltamarketplace_a14eef24f3593c93162ba746e26be0b6f1995b4c',
    editor: 'konicaminoltamarketplace_a14eef24f3593c93162ba746e26be0b6f1995b4c',
    valid: true,
    metadataKeys: 'Email,CC,BCC,Subject,Body,Convert,dropdown1,date1',
    created: 1708985400628,
    updated: 1708985400628,
    formStatus: 'published',
    formGroup: {
      id: '00000',
    },
    formDefinition: {
      targetMfpId: 'mfp_800x480',
      fields: [
        {
          type: 'pagebreak',
          id: 'MldfsMBMriFvSQCBihwC_',
          config: {
            variable: 'page1',
            title: '',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'text',
          id: 'zKrKQ6eLNN9k5prxMJaBK',
          config: {
            variable: 'Email',
            title: 'Email Address',
            defaultValue: '',
            minLength: '0',
            maxLength: '1000',
            required: true,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'text',
          id: 'HkKzk6cCR9CAUYlEZboO2',
          config: {
            variable: 'CC',
            title: 'CC',
            defaultValue: '',
            minLength: '0',
            maxLength: '1000',
            required: false,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'text',
          id: 'RQ0UZsqkRI94-DWC4YKXE',
          config: {
            variable: 'BCC',
            title: 'BCC',
            defaultValue: '',
            minLength: '0',
            maxLength: '1000',
            required: false,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'text',
          id: 'A_edY42UgauntmIf4ZEns',
          config: {
            variable: 'Subject',
            title: 'Subject',
            defaultValue: '',
            minLength: '0',
            maxLength: '1000',
            required: false,
            readonly: false,
            mask: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'textarea',
          id: 'hd_cBUfQKtStvQLtQ9sqa',
          config: {
            variable: 'Body',
            title: 'Email Body',
            defaultValue: '',
            minLength: '0',
            maxLength: '5000',
            required: false,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'checkbox',
          id: '51ATgYN3cfgtMJuqzxKo2',
          config: {
            variable: 'Convert',
            title: 'Convert to PDF?',
            defaultValue: '',
            trueLabel: 'Yes',
            falseLabel: 'No',
            readonly: false,
            checked: false,
            buttonsList: true,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'pagebreak',
          id: '5ivqeYXj9YwaEP2sk-U5v',
          config: {
            variable: 'page2',
            title: '',
            hideBack: false,
            hideNext: false,
            hideScan: false,
            hideHome: false,
            hideReturn: false,
            hidePageNumbers: false,
            showHelp: false,
            helpText: '',
          },
        },
        {
          type: 'dropdown',
          id: 'SN8ULPEQaxMg9h10A9uqM',
          config: {
            variable: 'dropdown1',
            title: 'Drop-Down',
            defaultValue: '',
            required: false,
            readonly: false,
            multiSelect: true,
            buttonsList: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
            options: [
              {
                id: 'c5jmWtD-tqmQnw0x6q343',
                label: 'Alabama',
                value: 'AL',
                show: true,
                isDefault: false,
              },
              {
                id: 'WgaJamsNFu4UZ1wDF67Jb',
                label: 'Alaska',
                value: 'AK',
                show: true,
                isDefault: false,
              },
              {
                id: 'LRCl6ua5W1UulgcMxA0N-',
                label: 'Arizona',
                value: 'AZ',
                show: true,
                isDefault: false,
              },
              {
                id: 'D_PE-stRJcFfCTgHd06qD',
                label: 'Arkansas',
                value: 'AR',
                show: true,
                isDefault: false,
              },
              {
                id: 'jmxrXfSeNdB-3oESBi8dc',
                label: 'California',
                value: 'CA',
                show: true,
                isDefault: false,
              },
              {
                id: '0T5-GWy7nt3SoxZYYax7Y',
                label: 'Colorado',
                value: 'CO',
                show: true,
                isDefault: false,
              },
              {
                id: 'uWDfrIcGJG_NVMqcknmWK',
                label: 'Connecticut',
                value: 'CT',
                show: true,
                isDefault: false,
              },
              {
                id: 'BJbclkUSQ96kq5snmN3kQ',
                label: 'Delaware',
                value: 'DE',
                show: true,
                isDefault: false,
              },
              {
                id: 's-KgJ0qVCNw1eduWMTA-G',
                label: 'Florida',
                value: 'FL',
                show: true,
                isDefault: false,
              },
              {
                id: 'Sl-hm3m3VVYeEgNyfdscg',
                label: 'Georgia',
                value: 'GA',
                show: true,
                isDefault: false,
              },
              {
                id: 'X-LcOwCh4r-w55gY9RsRG',
                label: 'Hawaii',
                value: 'HI',
                show: true,
                isDefault: false,
              },
              {
                id: 'krUW0AuQ-LLg04ylFLhLQ',
                label: 'Idaho',
                value: 'ID',
                show: true,
                isDefault: false,
              },
              {
                id: 'OcSCxbgypXFNAXDJpfchi',
                label: 'Illinois',
                value: 'IL',
                show: true,
                isDefault: false,
              },
              {
                id: 'BiPTFTpIvO_eFzDaJJFhz',
                label: 'Indiana',
                value: 'IN',
                show: true,
                isDefault: false,
              },
              {
                id: 'NnVHnJ9WOeJU3S_w-dbM1',
                label: 'Iowa',
                value: 'IA',
                show: true,
                isDefault: false,
              },
              {
                id: 'BHhW6NHEv79mwwpVQESWl',
                label: 'Kentucky',
                value: 'KY',
                show: true,
                isDefault: false,
              },
              {
                id: 'lCmnLpxPdv-EWkxZw-1Ip',
                label: 'Louisiana',
                value: 'LA',
                show: true,
                isDefault: false,
              },
              {
                id: 'mo02P8A7fgOoCPJ7JDnTn',
                label: 'Maine',
                value: 'ME',
                show: true,
                isDefault: false,
              },
              {
                id: 'w6sb7g3uOuQEiGWMm2z9G',
                label: 'Maryland',
                value: 'MD',
                show: true,
                isDefault: false,
              },
              {
                id: 'xW8hP5yv-R5fmy5M7ZP-4',
                label: 'Massachusetts',
                value: 'MA',
                show: true,
                isDefault: false,
              },
              {
                id: 'ysobtNwaBrg7lcK78ilSx',
                label: 'Michigan',
                value: 'MI',
                show: true,
                isDefault: false,
              },
              {
                id: '3yAWrXd155f0x_oJdFErQ',
                label: 'Minnesota',
                value: 'MN',
                show: true,
                isDefault: false,
              },
              {
                id: 'jC1fvnQZEybOzX3C1ha0f',
                label: 'Mississippi',
                value: 'MS',
                show: true,
                isDefault: false,
              },
              {
                id: '6qgYYePzKrnCgsUefi2Eo',
                label: 'Missouri',
                value: 'MO',
                show: true,
                isDefault: false,
              },
              {
                id: '_fJeR8zTsc4xckBvW2AOm',
                label: 'Montana',
                value: 'MT',
                show: true,
                isDefault: false,
              },
              {
                id: 'J8AA-evkRYxiWj49iSpyO',
                label: 'Nevada',
                value: 'NV',
                show: true,
                isDefault: false,
              },
              {
                id: 'Ff-b_O6QkYNlLtLvSnFoW',
                label: 'New Hampshire',
                value: 'NH',
                show: true,
                isDefault: false,
              },
              {
                id: 'QB9fTNkyyJ18T3hM18ftK',
                label: 'New Mexico',
                value: 'NM',
                show: true,
                isDefault: false,
              },
              {
                id: 'GlTnAMD1WV_ULlg4S4wbX',
                label: 'North Carolina',
                value: 'NC',
                show: true,
                isDefault: false,
              },
              {
                id: '-aUxrDBOM-j8oySn90Nc5',
                label: 'North Dakota',
                value: 'ND',
                show: true,
                isDefault: false,
              },
              {
                id: 'YncCTOXTchGYOVzpUR0ca',
                label: 'Ohio',
                value: 'OH',
                show: true,
                isDefault: false,
              },
              {
                id: 'SjbBwa9O6FIGP83azIA1M',
                label: 'Oklahoma',
                value: 'OK',
                show: true,
                isDefault: false,
              },
              {
                id: 'FsAGwWjYqz28ULA1uEDLA',
                label: 'Oregon',
                value: 'OR',
                show: true,
                isDefault: false,
              },
              {
                id: 'hyyMMHdXxfzus4FHzzupV',
                label: 'Pennsylvania',
                value: 'PA',
                show: true,
                isDefault: false,
              },
              {
                id: 'Jj7xRFAhzqQ5aDYVFGJv2',
                label: 'Rhode Island',
                value: 'RI',
                show: true,
                isDefault: false,
              },
              {
                id: 'uaS2BrdPginSjh5IHUuxR',
                label: 'Vermont',
                value: 'VT',
                show: true,
                isDefault: false,
              },
            ],
          },
        },
        {
          type: 'date',
          id: 'adNlpDmwwIewBCzbygnZD',
          config: {
            variable: 'date1',
            title: 'Date and Time field friendly name goes here',
            defaultValue: '',
            defaultValueLocale: '',
            minValue: '',
            minValueLocale: '',
            maxValue: '',
            maxValueLocale: '',
            returnFormat: '',
            defaultToNow: true,
            required: false,
            readonly: false,
            excludeFromMetadata: false,
            showHelp: false,
            helpText: '',
          },
        },
      ],
    },
  };
  return [200, form];
});
