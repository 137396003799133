import { produce } from 'immer';
import { FormDataType } from '../../../interfaces';
import {
  FormFieldTypeEnum,
  PageBreakField,
} from '../../../interfaces/FormFieldTypes';
import { FormActions } from './form-state-actions';

export enum FormActionType {
  POPULATE_FORM,
  SET_FIELD_VALUE,
  SET_FORM_ERROR,
  INCREMENT_PAGE,
  DECREMENT_PAGE,
  RESET_FORM,
  TOGGLE_DEBUG,
  SET_PAGE,
}

export const formStateReducer = produce(
  (state: FormDataType, action: FormActions) => {
    switch (action.type) {
      case FormActionType.POPULATE_FORM: {
        state = { ...action.payload };
        return state;
      }
      case FormActionType.SET_FIELD_VALUE: {
        const field = state.formDefinition.fields.find(
          (field) => field.id === action.payload.id,
        );
        if (field) {
          field.value = action.payload.value;
        }
        return state;
      }

      case FormActionType.SET_FORM_ERROR: {
        if (action.payload.message) {
          state.errors.set(action.payload.id, action.payload.message);
        } else {
          state.errors.delete(action.payload.id);
        }
        state.valid = !(state.errors.size > 0);
        return state;
      }

      case FormActionType.INCREMENT_PAGE: {
        if (state.currentPage.number < state.numPages) {
          state.currentPage.number++;
          const pageBreak = state.formDefinition.fields.filter(
            (field) => field.type === FormFieldTypeEnum.pageBreak,
          )[state.currentPage.number - 1] as PageBreakField;
          state.currentPage.config = pageBreak.config;
          state.currentPage.config.title =
            pageBreak.config.title || state.title;
        }
        return state;
      }
      case FormActionType.DECREMENT_PAGE: {
        if (state.currentPage.number > 1) {
          state.currentPage.number--;
          const pageBreak = state.formDefinition.fields.filter(
            (field) => field.type === FormFieldTypeEnum.pageBreak,
          )[state.currentPage.number - 1] as PageBreakField;
          state.currentPage.config = pageBreak.config;
          state.currentPage.config.title =
            pageBreak.config.title || state.title;
        }
        return state;
      }
      case FormActionType.SET_PAGE: {
        if (action.payload > state.numPages || action.payload < 0) return state;

        state.currentPage.number = action.payload;
        const pageBreak = state.formDefinition.fields.filter(
          (field) => field.type === FormFieldTypeEnum.pageBreak,
        )[state.currentPage.number - 1] as PageBreakField;
        state.currentPage.config = pageBreak.config;
        state.currentPage.config.title = pageBreak.config.title || state.title;
        return state;
      }
      case FormActionType.RESET_FORM: {
        state.loaded = false;
        return state;
      }

      case FormActionType.TOGGLE_DEBUG: {
        state.debug = !state.debug;
        return state;
      }
      default:
        return state;
    }
  },
);
