import { FC, memo, useState } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import {
	FileField as FileFieldType,
	FileFieldValue
} from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import AttachmentIcon from '../../assets/attach_file.svg';
import AddIcon from '@material-ui/icons/Add';
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
interface Props {
	field: FileFieldType;
}

const FileField: FC<any> = ({ field }: Props) => {
	const { dispatch } = useFormData();

	function handleFileRemove(value: FileFieldValue) {
		if (value.new) {
			//filter out file passed in param
			const filteredFileList = field.value.filter((val) => {
				if (!val.new) return true;
				else return val.file?.name !== value.file?.name;
			});
			dispatch({
				type: FormActionType.SET_FIELD_VALUE,
				payload: {
					id: field.id,
					value: filteredFileList,
				},
			});
		}
	}

	function handleAddFile(value: FileFieldValue) {
		dispatch({
			type: FormActionType.SET_FIELD_VALUE,
			payload: {
				id: field.id,
				value: [...field.value, value],
			},
		});
	}
	console.log('field value', field.value);

	return (
		<Grid container direction="column" key={field.id + '_field'}>
			<Grid item>
				<Typography variant="body1" style={{ lineHeight: '1.2em' }}>
					{field.config.title}
					{field.config.required ? ' *' : ''}
				</Typography>
			</Grid>
			<List>
				<FileInput
					field={field}
					index={0}
					onAddInput={() => { }}
					onChange={handleAddFile}
				/>
				{field.value.map((value, index) => (
					<ListItem key={index}>
						<ListItemText>
							{<span>{!value.new && value.fileId}</span>}
							{/* {value.new && ( */}
							{/* )} */}
						</ListItemText>
						<ListItemSecondaryAction>
							<IconButton onClick={() => handleFileRemove(value)}>
								<ClearIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Grid>
	);
};

function FileInput(props: {
	field: FileFieldType;
	onChange: (e: any) => void;
	onAddInput: () => void;
	index: number;
}) {
	const [inputValue, setInputValue] = useState<File>();
	console.log('inputValue', inputValue);
	return (
		<Grid container direction="row" wrap="nowrap">
			<Grid item xs>
				<div className="flex relative">
					<TextField
						fullWidth
						value={inputValue?.name}
						type={'text'}
						variant="outlined"
						InputLabelProps={{ shrink: true }}
						size="small"
						disabled={true}
					/>
					<label className="absolute right-3 top-4 cursor-pointer">
						<input
							style={{ display: 'none' }}
							onChange={(e) => {
								//@ts-ignore
								setInputValue(Array.from(e?.target?.files)[0]);
								props.onChange(e);
							}}
							type="file"
						/>
						<img
							src={AttachmentIcon}
							alt="attach file"
							style={{ scale: '2' }}
						/>
					</label>
				</div>
			</Grid>
			<Grid item>
				<IconButton onClick={() => props.onAddInput()}>
					<AddIcon color="primary" />
				</IconButton>
			</Grid>
		</Grid>
	);
}

export default memo(FileField);
