import { makeStyles } from '@material-ui/core/styles';
import StratusLogo from '../../assets/logo_stratus_white.svg';
import { memo } from 'react';

const useStyles = makeStyles({
  controls: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '& *': {
      margin: '0',
    },
  },
  header: {
    background: '#323232',
    display: 'flex',
    color: 'white',
    height: 50,
    padding: 7,
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  logo: {
    display: 'inline-flex',
  },
  text: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  text_holder: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  logout_text: {
    fontSize: 'medium',
    color: 'white',
  },
});

const isIFrame = window.self !== window.top;

const Header = () => {
  const classes = useStyles();

  if (isIFrame) {
    return <></>;
  }

  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <img src={StratusLogo} alt="" />
      </div>
    </div>
  );
};

export default memo(Header);
