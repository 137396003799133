import { FC, memo, useEffect, useMemo } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { NumberField as NumberFieldType } from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { Typography } from '@material-ui/core';

interface Props {
  field: NumberFieldType;
}

const NumberField: FC<any> = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  const validationErorr = useMemo(() => {
    const { minValue: min, maxValue: max, required } = field.config;
    if (min && Number(field.value) < min) return t('error valueLow', { min });
    if (max && Number(field.value) > max) return t('error valueHigh', { max });
    if (!field.value && required) return t('error empty');
    return '';
  }, [field.value, field.config]);

  useEffect(() => {
    if (field.config.readonly) return;
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: validationErorr,
      },
    });
  }, [field.config, field.id, validationErorr, dispatch]);

  function handleChange(e: any) {
    const re = /^[0-9\b]+$/;
    //check input against numbers only regex
    if (e.target.value === '' || re.test(e.target.value)) {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: e.target.value,
        },
      });
    }
  }

  return (
    <TextField
      fullWidth
      type={field.config.mask ? 'password' : 'text'}
      onChange={handleChange}
      key={field.id + '_field'}
      variant="outlined"
      size="small"
      label={
        <Typography
          variant="body1"
          style={{ textOverflow: 'ellipsis' }}
          className="whitespace-nowrap overflow-hidden"
        >
          {`${field.config.title} ${field.config.required ? '*' : ''}`}
        </Typography>
      }
      value={field.value}
      disabled={field.config.readonly}
      error={!!fieldError}
      helperText={fieldError}
      inputProps={{
        maxLength: 255,
      }}
    />
  );
};

export default memo(NumberField);
