import { useFormData } from '../context/FormState/FormDataContext';
import { DateTimeField as DateTimeFieldType } from '../../interfaces/FormFieldTypes';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, Typography, Grid } from '@material-ui/core';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { useEffect, useState, memo } from 'react';
import EventIcon from '@material-ui/icons/Event';
import { t } from 'i18next';
import { isAfter, isBefore, isValid, min, max } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useFormUtils } from '../hooks/useFormUtils';

type Props = {
  field: DateTimeFieldType;
};

const DateTimeField = memo(({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const { formatDate, parseDate } = useFormUtils();
  const fieldError = state.errors.get(field.id);
  const [controlValue, setControlValue] = useState(field.value);

  useEffect(() => {
    if (field.config.readonly) return;
    function validateField(): string {
      const { required } = field.config;
      if (!field.value) {
        if (required) return t('error empty');
      } else {
        const date = parseDate(field.value);
        if (!isValid(date)) return t('error dateInvalid');
        if (field.config.maxValue && isAfter(date, field.config.maxValue))
          return t('error dateMax', {
            max: formatDate(field.config.maxValue),
          });
        if (field.config.minValue && isBefore(date, field.config.minValue))
          return t('error dateMin', {
            min: formatDate(field.config.minValue),
          });
      }
      return '';
    }
    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field, dispatch, parseDate, formatDate]);

  const onDateSelect = (date: Date) => {
    if (isValid(date))
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: formatDate(date),
        },
      });
    setControlValue(formatDate(date));
  };

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setControlValue(e.target.value);
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.value,
      },
    });
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <div className="flex relative">
          <TextField
            label={
              <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
                {field.config.title}
                {field.config.required ? ' *' : ''}
              </Typography>
            }
            size="small"
            variant="outlined"
            className={'w-full'}
            value={controlValue}
            onChange={onDateChange}
            error={!!fieldError}
            disabled={field.config.readonly}
            helperText={fieldError}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: {
                paddingRight: 20,
              },
            }}
          />

          {!field.config.readonly && (
            <div className="absolute right-2 top-1">
              <DatePicker
                // Goal: always keep datepicker within range and use current date if within range
                // use field.value if value is valid
                // if field.value not valid use current date
                // if no field.value take min(maxValue, max(minValue, new Date()))
                // if minValue or maxValue does not exist fallback to current Date when taking min/max
                selected={
                  field.value
                    ? isValid(parseDate(field.value))
                      ? parseDate(field.value)
                      : new Date()
                    : field.config.maxValue
                    ? min([
                        new Date(field.config.maxValue),
                        field.config.minValue
                          ? max([new Date(field.config.minValue), new Date()])
                          : new Date(),
                      ])
                    : new Date()
                }
                onChange={onDateSelect}
                customInput={<EventIcon color="primary" />}
                popperPlacement="left"
                minDate={field.config.minValue}
                maxDate={field.config.maxValue}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
});

export default DateTimeField;
