import axios from '../../lib/axios';
import { useMemo } from 'react';
import { useAppConfig } from '../context/AppState/AppContext';
import { useAppArgs } from './useAppArgs';
import jwt from 'jsonwebtoken';
import i18 from 'i18next';
import { useAuthContext } from '../context/AuthState/AuthContext';

export const useMetadataApi = () => {
  const { state: appState } = useAppConfig();
  const { state: authState } = useAuthContext();
  const { fallbackDomain, tenant } = useAppArgs();
  const { workflowId } = appState;

  const metadataApi = useMemo(() => {
    async function getProcessMetadata(processId: string) {
      console.log(workflowId, processId);
      const response = await axios.get(
        `https://${
          tenant.region ?? 'us-east-1'
        }.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${processId}`,
      );
      return response.data;
    }

    async function uploadProcessMetadata(processId: string, payload: any) {
      const response = await axios.put(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${processId}`,
        payload,
      );
      return response.data;
    }

    async function getFileMetadata(fileId: string) {
      console.log(appState.processId, fileId, tenant.region, fallbackDomain);
      const response = await axios.get(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/file/${appState.processId}/${fileId}`,
      );
      return response.data;
    }

    async function uploadFileMetadata(fileId: string, payload: any) {
      const response = await axios.put(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/file/${appState.processId}/${fileId}`,
        payload,
      );
      return response.data;
    }

    async function uploadFormToProcessMetadata(
      processId: string,
      formId: string,
      payload: any,
      extraData: any,
    ) {
      console.log('processId', processId);
      try {
        const processData = await getProcessMetadata(processId);
        if (!processData.data) {
          throw new Error('Unknown response');
        }
        const updatedProcessData = {
          ...processData,
        };

        //form metadata
        if (processData.data?.forms) {
          updatedProcessData.data.forms = {
            ...processData.data.forms,
            [formId]: payload,
          };
        } else {
          updatedProcessData.data.forms = {
            [formId]: payload,
          };
        }

        //extra data like starting node metadata
        updatedProcessData.data = {
          ...updatedProcessData.data,
          ...extraData,
        };

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        //form submissions
        if (processData.data?.formsubmissions) {
          updatedProcessData.data.formsubmissions = {
            ...processData.data.formsubmissions,
            [formId]: {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            },
          };
        } else {
          updatedProcessData.data.formsubmissions = {
            [formId]: {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            },
          };
        }

        const response = await uploadProcessMetadata(
          processId,
          updatedProcessData,
        );
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    async function uploadFormSubmissionToProcessMetadata(
      processId: string,
      formId: string,
    ) {
      console.log('processId', processId);
      try {
        const processData = await getProcessMetadata(processId);
        if (!processData.data) {
          throw new Error('Unknown response');
        }
        const updatedProcessData = {
          ...processData,
        };

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        //form submissions
        if (processData.data?.formsubmissions) {
          updatedProcessData.data.formsubmissions = {
            ...processData.data.formsubmissions,
            [formId]: {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            },
          };
        } else {
          updatedProcessData.data.formsubmissions = {
            [formId]: {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            },
          };
        }

        const response = await uploadProcessMetadata(
          processId,
          updatedProcessData,
        );
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    async function uploadFormSubmissionToFileMetadata(
      fileId: string,
      formId: string,
    ) {
      console.log('processId', appState.processId);
      try {
        const processData = await getFileMetadata(fileId);
        if (!processData.data) {
          throw new Error('Unknown response');
        }
        const updatedProcessData = {
          ...processData,
        };

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        //form submissions
        if (processData.data?.formsubmissions) {
          updatedProcessData.data.formsubmissions = {
            ...processData.data.formsubmissions,
            [formId]: {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            },
          };
        } else {
          updatedProcessData.data.formsubmissions = {
            [formId]: {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            },
          };
        }

        const response = await uploadFileMetadata(fileId, updatedProcessData);
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    async function uploadFormToFileMetadata(
      fileId: string,
      formId: string,
      payload: any,
    ) {
      try {
        console.log('fileId', fileId);
        const fileData = await getFileMetadata(fileId);
        console.log(fileData);
        if (!fileData.data) {
          throw new Error('Unknown response');
        }
        const updatedFileMetadata = {
          ...fileData,
        };

        //form metadata
        if (fileData.data?.form) {
          updatedFileMetadata.data.form = {
            ...fileData.data.form,
            // [formId]: payload,
            ...payload,
          };
        } else {
          updatedFileMetadata.data.form = {
            // [formId]: payload,
            ...payload,
          };
        }

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        const response = await uploadFileMetadata(fileId, updatedFileMetadata);
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    return {
      getProcessMetadata,
      uploadProcessMetadata,
      getFileMetadata,
      uploadFileMetadata,
      uploadFormToProcessMetadata,
      uploadFormToFileMetadata,
      uploadFormSubmissionToProcessMetadata,
      uploadFormSubmissionToFileMetadata,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, fallbackDomain, workflowId]);
  return metadataApi;
};
