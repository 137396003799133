import { FC, useEffect, memo, useMemo } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { TextField as TextFieldType } from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { Typography } from '@material-ui/core';

interface Props {
  field: TextFieldType;
}

const GeneralTextField: FC<any> = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = field && state.errors.get(field.id);

  const validationError = useMemo(() => {
    const { required, minLength, maxLength } = field.config;
    if (maxLength && field.value.length > field.config.maxLength)
      return t('error valueLong', {
        max: field.config.maxLength.toString(),
      });
    if (minLength && field.value.length < field.config.minLength)
      return t('error valueShort', {
        min: field.config.minLength.toString(),
      });
    if (required && !field.value) return t('error empty');
    return '';
  }, [field.value, field.config]);

  useEffect(() => {
    if (field?.config?.readonly) return; //skip validation if field is readonly
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: validationError,
      },
    });
  }, [field, validationError, dispatch]);

  function handleChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.value,
      },
    });
  }

  return (
    <TextField
      label={
        <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
          {field.config.title}
          {field.config.required ? ' *' : ''}
        </Typography>
      }
      fullWidth
      type={field.config.mask ? 'password' : 'text'}
      key={field.id + '_field'}
      variant="outlined"
      size="small"
      value={field.value || ''}
      onChange={handleChange}
      error={!!fieldError}
      disabled={field.config.readonly}
      inputProps={{
        maxLength: 255,
      }}
      helperText={fieldError}
    />
  );
};

export default memo(GeneralTextField);
