import { Link } from '@material-ui/core';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../context/AppState/AppContext';

export const ContactUsLink = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <Link
      href={`https://${state.domain}/contact-us`}
      underline="hover"
      target="_blank"
      rel="noreferrer"
      className="text-white"
    >
      {t('Contact Us')}
    </Link>
  );
};
