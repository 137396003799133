import { useMemo } from 'react';
export const useAppArgs = () => {
  const search = window.location.search;
  let pathParams = window.location.pathname.split('/');
  pathParams.shift(); // remove first empty string
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  let processId = '',
    workflowId = '',
    nodeID = '',
    returnURL = '',
    returnToken = '',
    debug = false,
    mock = false,
    metadata: any = {},
    tenant = { id: '', region: '', slug: '', name: '', plan: '' },
    fallbackDomain = '',
    domain = '',
    urlId = '',
    formId = '',
    fileId = '';

  if (search) {
    const decodedQs = search.replace('?', '');
    const queryParams = new URLSearchParams(decodedQs);
    processId = queryParams.get('arn') ?? '';
    workflowId = queryParams.get('workflowId') ?? '';
    nodeID = queryParams.get('nodeId') ?? '';
    returnURL = queryParams.get('url') ?? '';
    returnToken = queryParams.get('token') ?? '';
    fileId = queryParams.get('fileId') ?? '';
    metadata = JSON.parse(
      decodeURIComponent(queryParams.get('metadata') ?? '{}'),
    );
    if (metadata?.tenant) {
      tenant = metadata.tenant;
    }
    debug = queryParams.has('debug');
    mock = queryParams.has('mock');
  }
  domain = window.location.hostname.split('.').slice(-2).join('.');

  if (domain) {
    // use a fallback domain for the Forms Backend API's in case the request is from any one of the following domains.
    const altDomains: Record<string, string> = {
      localhost: 'stratus.lol',
      'scantripcloud.lol': 'stratus.lol',
      'scantripcloud.xyz': 'dodgestrat.us',
      'dispatcherstratus.com': 'scantripcloud.com',
    };
    fallbackDomain = altDomains[domain.toLowerCase()] || domain;
  }

  if (pathParams[0] !== 'client') {
    tenant.region = pathParams[1] || '';
    tenant.slug = pathParams[2] || '';
    urlId = pathParams[3] || '';
    tenant.name = pathParams[4] || '';
    tenant.id = pathParams[5] || '';
    tenant.plan = pathParams[6] || '';
  }
  return {
    processId,
    workflowId,
    nodeID,
    metadata,
    returnURL,
    returnToken,
    debug,
    mock,
    domain,
    fallbackDomain,
    tenant,
    urlId,
    queryParams,
    pathParams,
    formId,
    fileId,
  } as const;
};
