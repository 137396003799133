import {
  FormControl,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  Button,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { DropDownField as DropDownFieldType } from '../../interfaces/FormFieldTypes';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { useEffect, memo } from 'react';
import { t } from 'i18next';

type Props = {
  field: DropDownFieldType;
};

const DropdownField = memo(({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  useEffect(() => {
    if (field.config.readonly) return;
    function validateField(value: DropDownFieldType['value']): string {
      if (field.config.required) {
        if (!!!value.length) return t('error empty');
      }
      return '';
    }
    const error = validateField(field.value);
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field.value, dispatch, field.id, field.config]);

  function handleChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: field.config.multiSelect ? e.target.value : [e.target.value],
      },
    });
  }
  return (
    <Grid item xs={12}>
      <FormControl size="small" fullWidth>
        <TextField
          size="small"
          select
          SelectProps={{
            multiple: field.config.multiSelect,
            onChange: handleChange,
            renderValue: (selected: any) =>
              field.config.options
                .filter((option) =>
                  field.config.multiSelect
                    ? selected.includes(option.value)
                    : selected === option.value,
                )
                .map((option) => option.label)
                .join(', '),
          }}
          value={
            field.value.length === 0
              ? field.config.multiSelect
                ? []
                : ''
              : field.config.multiSelect
                ? field.value
                : field.value[0]
          }
          variant="outlined"
          label={
            <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
              {field.config.title}
              {field.config.required ? ' *' : ''}
            </Typography>
          }
          onChange={handleChange}
          disabled={field.config.readonly}
          error={!!fieldError}
          style={{ fontSize: '1rem' }}
        >
          {field.config.options
            .filter((option) => option.show)
            .map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  value={option.value}
                  style={{ minHeight: '2em' }}
                >
                  {field.config.multiSelect && (
                    <Checkbox
                      color="primary"
                      checked={field.value.includes(option.value)}
                    />
                  )}
                  <Typography
                    variant="body1"
                    className="break-all whitespace-normal"
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              );
            })}
        </TextField>
        <FormHelperText>
          <Typography variant="subtitle2" style={{ color: 'red' }}>
            {fieldError}
          </Typography>
        </FormHelperText>
      </FormControl>
    </Grid>
  );
});

const ButtonList = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  function handleClick(option: { label: string; value: string }) {
    if (field.config.multiSelect) {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: field.value.includes(option.value)
            ? field.value.filter((value) => value !== option.value)
            : [...field.value, option.value],
        },
      });
    } else {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: [option.value],
        },
      });
    }
  }

  useEffect(() => {
    function validateField(value: DropDownFieldType['value']): string {
      if (field.config.required) {
        if (!!!value.length) return t('error empty');
      }
      return '';
    }
    if (field.config.readonly) return;
    const error = validateField(field.value);
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field.value, dispatch, field.id, field.config]);
  return (
    <Grid container spacing={1} direction="column">
      <Grid item xs={12}>
        <Typography variant="body1">{field.config.title}</Typography>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        {field.config.options
          .filter((option) => option.show)
          .map((option) => {
            const selected = field.value.includes(option.value);
            return (
              <Grid item key={option.id}>
                <Button
                  disabled={field.config.readonly}
                  style={{
                    opacity: selected ? '100%' : '50%',
                    minHeight: '100%',
                  }}
                  color={selected ? 'primary' : 'default'}
                  onClick={() => handleClick(option)}
                  variant="contained"
                >
                  <span className="break-all">{option.label}</span>
                </Button>
              </Grid>
            );
          })}
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" style={{ color: 'red' }}>
          {fieldError}
        </Typography>
      </Grid>
    </Grid>
  );
};

const DropdownWrapper = ({ field }: Props) => {
  if (field.config.buttonsList) return <ButtonList field={field} />;
  return <DropdownField field={field} />;
};

export default memo(DropdownWrapper);
