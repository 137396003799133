import { memo, useContext } from 'react';
import Spinner from '../Spinner';
import ActionBar from '../actionBar';
import { Body } from './body';
import Header from './Header';
import {
  NodeContext,
  NodeContextType,
} from '@dispatcher-stratus/stratus-react';
import { DebugWindow } from '../DebugWindow';
import PageNumberChip from './PageNumberChip';
import { useFormData } from '../context/FormState/FormDataContext';
import { AppContext } from '../context/AppState/AppContext';

export declare interface Props {
  children?: React.ReactNode; // best, accepts everything React can render
  next?: string;
  back?: string;
  onSubmit: () => Promise<void>;
}

const Layout = (props: Props): JSX.Element => {
  const { state } = useContext<NodeContextType>(NodeContext);
  const { state: formState } = useFormData();
  const { state: appState } = useContext(AppContext);

  return (
    <>
      <div className="bg-white h-screen flex flex-col ">
        <Spinner />
        <Header
          className="h-50px bg-mfpHeader px-5 flex-shrink-0"
          user={state.username}
          tenant={state.tenant?.name}
        ></Header>
        <ActionBar
          onSubmit={props.onSubmit}
          className="h-56px bg-mfpActionBar px-2.5 py-2 flex-shrink-0 pl-4"
        ></ActionBar>
        <Body className="justify-center overflow-x-auto h-full overflow-y-auto">
          {props.children}
        </Body>
        {!formState.currentPage.config.hidePageNumbers && (
          <div className="absolute bottom-1 right-10">
            <PageNumberChip />
          </div>
        )}
        <div>{appState.debug && <DebugWindow />}</div>
      </div>
    </>
  );
};

export default memo(Layout);
