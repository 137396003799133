import { expandString } from '@dispatcher-stratus/metadata';
import {
  CheckboxField,
  DateTimeField,
  DropDownField,
  FileField,
  FormField,
  FormFieldTypeEnum,
  HiddenField,
  NumberField,
  PageBreakField,
  PasswordField,
  TextAreaField,
  TextField,
} from '../../interfaces/FormFieldTypes';
import { parse } from 'date-fns';
import { FormDataType, INITIAL_FORM_CONFIG } from '../../interfaces';
import jaLocale from 'date-fns/locale/ja';
import esLocale from 'date-fns/locale/es';
import deLocale from 'date-fns/locale/de';
import itLocale from 'date-fns/locale/it';
import frLocale from 'date-fns/locale/fr';
import zhLocale from 'date-fns/locale/zh-CN';
import i18next from '../../i18n';
import { formatWithOptions } from 'date-fns/fp';
import { useMemo } from 'react';

export const useFormUtils = () => {
  const Utils = useMemo(() => {
    const visibleFields = [
      FormFieldTypeEnum.text,
      FormFieldTypeEnum.textarea,
      FormFieldTypeEnum.password,
      FormFieldTypeEnum.checkbox,
      FormFieldTypeEnum.datetime,
      FormFieldTypeEnum.dropdown,
      FormFieldTypeEnum.label,
      FormFieldTypeEnum.number,
      FormFieldTypeEnum.password,
    ];
    function compileFormData(formFields: FormField[]) {
      const filteredFields = formFields.filter(
        (field) =>
          !field.config.excludeFromMetadata &&
          field.type !== FormFieldTypeEnum.label &&
          field.type !== FormFieldTypeEnum.pageBreak,
      );
      const exportFields: any = {};
      filteredFields.forEach((field) => {
        const massagedField = massageFormFieldExport(field);
        if (field.type === FormFieldTypeEnum.datetime) {
          exportFields[
            massagedField.config.variable +
            '-ISO' +
            (filteredFields
              .map((f) => f.config.variable)
              .includes(field.config.variable + '-ISO')
              ? '-1'
              : '')
          ] = parseDate(field.value).toISOString();
        }

        exportFields[massagedField.config.variable] = massagedField.value;
      });
      return exportFields;
    }

    function massageFormFieldImport(
      field: FormField,
      metadata: { environment: Map<any, any>; records: Map<string, any> },
      defaultValueOverrides: { [variable: string]: string } = {},
    ) {
      switch (field.type) {
        case FormFieldTypeEnum.dropdown:
          const dropdownField: DropDownField = JSON.parse(
            JSON.stringify(field),
          );
          dropdownField.config.options = field.config.options
            .filter((option) => option.show)
            .map((option) => ({
              ...option,
              value: expandString(option.value, metadata),
              label: expandString(option.label, metadata),
            }));

          let dropdownFieldValue = [];
          if (defaultValueOverrides[field.config.variable]) {
            dropdownFieldValue = defaultValueOverrides[field.config.variable]
              .split(',')
              .map((val) => val.trim());
          } else {
            dropdownFieldValue = dropdownField.config.options
              .filter((option: { isDefault: boolean }) => option.isDefault)
              .map((option: { value: string }) => option.value);
          }
          dropdownField.value = dropdownFieldValue;
          dropdownField.visible = visibleFields.includes(field.type);

          return dropdownField;

        case FormFieldTypeEnum.number:
          const numberField: NumberField = JSON.parse(JSON.stringify(field));
          numberField.value =
            defaultValueOverrides[field.config.variable] ??
            numberField.config.defaultValue;
          numberField.config.minValue = Number(numberField.config.minValue);
          numberField.config.maxValue = Number(numberField.config.maxValue);
          numberField.visible = visibleFields.includes(field.type);
          return numberField;

        case FormFieldTypeEnum.datetime:
          const dateTimeField: DateTimeField = JSON.parse(
            JSON.stringify(field),
          );
          const { defaultToNow, defaultValue, minValue, maxValue } =
            field.config;
          let dateTimeFieldValue = '';
          if (defaultValueOverrides[field.config.variable]) {
            dateTimeFieldValue = formatDate(
              new Date(defaultValueOverrides[field.config.variable]),
            );
          } else if (defaultValue) {
            dateTimeFieldValue = formatDate(new Date(defaultValue));
          } else if (defaultToNow) {
            dateTimeFieldValue = formatDate();
          }
          dateTimeField.value = dateTimeFieldValue;

          if (minValue) dateTimeField.config.minValue = new Date(minValue);
          if (maxValue) dateTimeField.config.maxValue = new Date(maxValue);
          dateTimeField.visible = visibleFields.includes(field.type);
          return dateTimeField;

        case FormFieldTypeEnum.checkbox:
          const checkboxField: CheckboxField = JSON.parse(
            JSON.stringify(field),
          );
          checkboxField.value = Boolean(
            defaultValueOverrides[field.config.variable] ??
            field.config.checked,
          );
          checkboxField.config.trueLabel = expandString(
            field.config.trueLabel,
            metadata,
          );
          checkboxField.config.falseLabel = expandString(
            field.config.falseLabel,
            metadata,
          );
          checkboxField.visible = visibleFields.includes(field.type);
          return checkboxField;

        case FormFieldTypeEnum.file:
          const fileField: FileField = JSON.parse(
            JSON.stringify(field),
          );;
          fileField.value = [];
          if (defaultValueOverrides[field.config.variable]) {
            fileField.value = defaultValueOverrides[field.config.variable]
              .split(',')
              .map((fileId: string) => ({
                new: false,
                fileId,
              }));
          }

          fileField.config.title = field.config.title
          fileField.config.fileTypes = field.config.fileTypes
          fileField.config.maxFileSize = Number(field.config.maxFileSize)
          fileField.config.maxFileSizeUnits = field.config.maxFileSizeUnits
          fileField.config.allowMultipleFiles = Boolean(field.config.allowMultipleFiles)
          fileField.config.required = field.config.required
          fileField.config.defaultValue = ''
          fileField.visible = visibleFields.includes(field.type)

          return fileField;
        default:
          const genericField:
            | TextField
            | TextAreaField
            | PasswordField
            | HiddenField
            | PageBreakField = JSON.parse(JSON.stringify(field));
          if (
            (genericField as TextField | TextAreaField).config.defaultValue !==
            undefined
          ) {
            genericField.value = expandString(
              defaultValueOverrides[field.config.variable] ??
              ((field as TextField | TextAreaField).config?.defaultValue ||
                ''),
              metadata,
            );
          }
          genericField.visible = visibleFields.includes(field.type);
          return genericField;
      }
    }

    function massageFormFieldExport(field: FormField) {
      const massagedField: FormField = JSON.parse(JSON.stringify(field));
      switch (field.type) {
        case FormFieldTypeEnum.dropdown:
          massagedField.value = field.value.join(', ');
          break;

        case FormFieldTypeEnum.datetime:
          massagedField.value = field.config.returnFormat
            ? formatDate(parseDate(field.value), field.config.returnFormat)
            : field.value;
          break;
      }

      return massagedField;
    }

    function parseTargetDimensions(mfpId: string) {
      const regex = /\d{3,4}x\d{3,4}/;
      const matches = mfpId.match(regex);
      if (!matches || matches.length === 0) {
        console.error('FAILED TO EXTRACT FORM DIMENSIONS FROM ', mfpId);
        //fallback to screen size
        return {
          height: window.innerHeight,
          width: window.innerWidth,
        };
      }
      const [width, height] = matches[0].split('x');
      return {
        height: Number.parseInt(height),
        width: Number.parseInt(width),
      };
    }

    function massageFormInput(
      payload: any,
      metadata: { environment: Map<any, any>; records: Map<string, any> },
      defaultValueOverrides: { [variable: string]: string } = {},
    ) {
      return new Promise<FormDataType>((resolve, reject) => {
        console.log('this running');
        const formDimensions = parseTargetDimensions(
          payload.formDefinition.targetMfpId,
        );
        let newForm = INITIAL_FORM_CONFIG;
        newForm.id = payload.id;
        newForm.width = formDimensions.width;
        newForm.height = formDimensions.height;
        newForm.title = payload.title;
        newForm.creator = payload.creator;
        newForm.editor = payload.editor;
        newForm.created = payload.created;
        newForm.updated = payload.updated;
        newForm.formStatus = payload.formStatus;
        newForm.formGroup = payload.formGroup;
        newForm.valid = true;
        newForm.formDefinition = JSON.parse(
          JSON.stringify(payload.formDefinition),
        );
        let page: number = 0;
        newForm.formDefinition.fields = payload.formDefinition.fields.map(
          (field: any) => {
            field = massageFormFieldImport(
              field,
              metadata,
              defaultValueOverrides,
            );
            if (field.type === FormFieldTypeEnum.pageBreak) {
              page++;
              if (field.config.showHelp) field.visible = true;
            }
            field.page = page;
            return field;
          },
        );
        newForm.currentPage = {
          config: (newForm.formDefinition.fields[0] as PageBreakField).config,
          number: 1,
        };
        newForm.currentPage.config.title =
          newForm.currentPage.config.title || newForm.title;
        newForm.numPages = page;
        newForm.loaded = true;
        resolve(newForm);
      });
    }

    function formatDate(date?: Date, fmt?: string) {
      const formatter = formatWithOptions(
        { locale: getLocale(i18next.language) },
        fmt ?? 'P, pp',
      );
      return formatter(date ?? new Date());
    }

    function getLocale(locale: string) {
      const locales: { [key: string]: Locale } = {
        es: esLocale,
        de: deLocale,
        fr: frLocale,
        it: itLocale,
        ja: jaLocale,
        'zh-CN': zhLocale,
      };
      return locales[locale];
    }

    function parseDate(dateString: string) {
      return parse(dateString || formatDate(new Date()), 'P, pp', new Date(), {
        locale: getLocale(i18next.language) ?? null,
      });
    }

    return {
      compileFormData,
      massageFormFieldExport,
      massageFormFieldImport,
      parseTargetDimensions,
      massageFormInput,
      formatDate,
      parseDate,
    };
  }, []);

  return Utils;
};
