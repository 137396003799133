import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.5em',
      fontWeight: '400'
    }
  }
})

const Loading = () => {
  const classes = useStyles()
  return (
    <div className={classes.loading}>

    </div>
  )
}

export default Loading
