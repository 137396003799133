import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

function ResetButton() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={async () => {
        console.log('restoring form');
        queryClient.invalidateQueries('processData');
        queryClient.invalidateQueries('formData');
      }}
    >
      {t('Reset')}
    </Button>
  );
}

export default ResetButton;
