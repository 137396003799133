import { Button, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormData } from '../context/FormState/FormDataContext';
import { useMetadataApi } from '../hooks/useMetadataApi';
import { useFormUtils } from '../hooks/useFormUtils';
import { useAppConfig } from '../context/AppState/AppContext';

function SaveButton() {
  const { t } = useTranslation();
  const { uploadFormToProcessMetadata } = useMetadataApi();
  const { compileFormData } = useFormUtils();
  const { state: formState } = useFormData();
  const { state: appState } = useAppConfig();

  const CustomButton = withStyles({
    root: {
      borderRadius: 4,
      fontWeight: 600,
      color: 'white',
      backgroundColor: '#5f3fa5',
    },
  })(Button);

  return (
    <CustomButton
      variant="contained"
      disabled={!!!formState.valid}
      onClick={async () => {
        console.log('saving form');
        const compiledFormFields = await compileFormData(
          formState.formDefinition.fields,
        );
        console.log('compiled', compiledFormFields);
        const response = await uploadFormToProcessMetadata(
          appState.processId,
          appState.formId,
          compiledFormFields,
          {},
        );
        console.log(response);
      }}
    >
      {t('Save')}
    </CustomButton>
  );
}

export default SaveButton;
