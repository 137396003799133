import { useContext } from 'react';
import { useFormData } from './context/FormState/FormDataContext';
import Field from './form-fields/Field';
import { Grid } from '@material-ui/core';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { FormField } from '../interfaces/FormFieldTypes';
import { useQuery } from 'react-query';
import { trackPromise } from 'react-promise-tracker';
import { useAppArgs } from './hooks/useAppArgs';
import { useAuthContext } from './context/AuthState/AuthContext';
import { getForm } from '../lib/formApi';
import { useFormUtils } from './hooks/useFormUtils';
import { FormActionType } from './context/FormState/form-state-reducer';
import { AxiosError } from 'axios';

export const Form = (props: { showSubmit?: boolean }) => {
  const { state: formState } = useFormData();

  if (!formState.loaded) return <></>;

  /** Render */
  return (
    <div className="text-xl w-full px-10 pt-5">
      <Grid
        id="formGrid"
        container
        direction="column"
        alignItems="center"
        spacing={5}
      >
        {formState.formDefinition.fields
          .filter(
            (field: FormField) => field.page === formState.currentPage.number,
          )
          .map((field: FormField) => (
            <Field key={field.id + '_field'} field={field} />
          ))}
      </Grid>
    </div>
  );
};

export default Form;

type FormWrapperProps = {
  formID: string;
};
export const FormWrapper = ({ formID }: FormWrapperProps) => {
  const { dispatch: formDispatch } = useFormData();
  const { fallbackDomain, metadata } = useAppArgs();
  const { state: authState } = useAuthContext();
  const { massageFormInput } = useFormUtils();
  const { dispatch } = useContext(MessageBoxContext);

  const { isLoading } = useQuery(
    ['formData', formID],
    async () => {
      const formData = await trackPromise(
        getForm(
          metadata.tenant.region,
          metadata.tenant.slug,
          fallbackDomain,
          formID,
          authState.token!,
        ),
      );
      const massagedFormData = await massageFormInput(formData, {
        environment: new Map(),
        records: new Map(),
      });
      formDispatch({
        type: FormActionType.POPULATE_FORM,
        payload: massagedFormData,
      });
      return massagedFormData;
    },
    {
      enabled: !!formID && !!authState.token,
      onError: (err: AxiosError) => {
        dispatch({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );
  return <>{!isLoading && <Form />}</>;
};
