import { memo, useContext } from 'react';
import Spinner from '../Spinner';
import { Body } from '../mfp/body';
import { DebugWindow } from '../DebugWindow';
import { AppContext } from '../context/AppState/AppContext';
import Header from '../embed/Header';
import { AppFooter } from '../footer/app-footer';
import FooterControls from './FooterControls/FooterControls';
import FormTitle from './FormTitle';

export declare interface Props {
  children?: React.ReactNode; // best, accepts everything React can render
  next?: string;
  back?: string;
  disableSubmit?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
  onSubmit: () => Promise<void>;
}

const Layout = (props: Props): JSX.Element => {
  const { state: appState } = useContext(AppContext);

  return (
    <>
      <div className="bg-white h-screen flex flex-col ">
        <Spinner />
        {!props.hideHeader && <Header />}
        <FormTitle />
        {/* <ActionBar
          className="h-56px bg-mfpActionBar px-2.5 py-2 flex-shrink-0 pl-4"
          hideHome
          onSubmit={props.onSubmit}
          disableSubmit={props.disableSubmit}
        ></ActionBar> */}
        <Body className="justify-center overflow-x-auto h-full overflow-y-auto">
          {props.children}
        </Body>
        {<FooterControls />}
        {!props.hideFooter && <AppFooter />}
        <div>{appState.debug && <DebugWindow />}</div>
      </div>
    </>
  );
};

export default memo(Layout);
