import { useTranslation } from 'react-i18next';
import { ContactUsLink } from './contact-us-link';
import { CookieSettings } from './cookie-settings';
import { EulaLink } from './eula-link';
import { FooterDivider } from './footer-divider';
import { PrivacyLink } from './privacy-link';
import { TermsConditionsLink } from './terms-conditions-link';

const isIFrame = window.self !== window.top;

export const AppFooter = () => {
  const { t } = useTranslation();

  if (isIFrame) {
    return <></>;
  }

  return (
    <div
      className="flex p-2 h-8 bg-theme-footer text-white text-xs"
      style={{ background: '#323232' }}
    >
      <ContactUsLink />
      <FooterDivider />
      <PrivacyLink />
      <FooterDivider />
      <CookieSettings />
      <FooterDivider />
      <TermsConditionsLink />
      <FooterDivider />
      <EulaLink />
      <div className="flex-1 text-right m-auto pr-2 text-xs">
        {t('Developed by')} Solutions Engineering Center &#169; 2024 Konica
        Minolta Business Solutions, U.S.A., Inc.
      </div>
    </div>
  );
};

export * from './contact-us-link';
export * from './eula-link';
export * from './footer-divider';
export * from './privacy-link';
export * from './terms-conditions-link';
