import { FC, memo, useEffect } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { TextField as TextFieldType } from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { Typography } from '@material-ui/core';

interface Props {
  field: TextFieldType;
}

const PasswordField: FC<any> = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  useEffect(() => {
    if (field.config.readonly) return; //skip validation if field is readonly
    function validateField(): string {
      const { required, minLength, maxLength } = field.config;
      if (maxLength && field.value.length > field.config.maxLength)
        return t('error valueLong', {
          max: field.config.maxLength.toString(),
        });
      if (minLength && field.value.length < field.config.minLength)
        return t('error valueShort', {
          min: field.config.minLength.toString(),
        });
      if (required && !field.value) return t('error empty');
      return '';
    }
    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field, dispatch]);

  function handleChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.value,
      },
    });
  }

  return (
    <form>
      <input hidden autoComplete="username"></input>
      <TextField
        autoComplete="new-password"
        fullWidth
        type={'password'}
        key={field.id + '_field'}
        variant="outlined"
        size="small"
        label={
          <Typography variant="body1" style={{ lineHeight: '1.2em' }}>
            {field.config.title}
            {field.config.required ? ' *' : ''}
          </Typography>
        }
        value={field.value || ''}
        onChange={handleChange}
        error={!!fieldError}
        disabled={field.config.readonly}
        helperText={fieldError}
        inputProps={{
          maxLength: field.config?.maxLength || 255,
        }}
      />
    </form>
  );
};

export default memo(PasswordField);
