import { Grid, Typography, Button } from '@material-ui/core';
import Layout from './layout';
import SuccessIcon from '../../assets/submitted.svg';
import { useContext } from 'react';
import { AppContext } from '../context/AppState/AppContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { useQueryClient } from 'react-query';

function Success() {
  const { setAppConfig } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch: formDispatch } = useContext(FormDataContext);
  const queryClient = useQueryClient();
  return (
    <Layout onSubmit={() => {}}>
      <Grid
        container
        direction="column"
        item
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item className="m-2">
          <img src={SuccessIcon} alt="success icon" />
        </Grid>
        <Grid item className="m-2">
          <Typography variant="h4">
            {'Thank you for your submission!'}
          </Typography>
        </Grid>
        <Grid item className="m-2">
          <Typography variant="body1">
            {'Would you like to make another submission?'}
          </Typography>
        </Grid>
        <Grid item className="m-2">
          <Button
            onClick={() => {
              setAppConfig({
                arn: '',
                processId: '',
                returnToken: '',
                returnUrl: '',
              });

              formDispatch({
                type: FormActionType.RESET_FORM,
              });
              queryClient.invalidateQueries('formUrlData');
              queryClient.invalidateQueries('formData');
              history.goBack();
            }}
            style={{
              backgroundColor: '#5f3fa5',
              color: 'white',
              borderRadius: 4,
              fontWeight: 600,
            }}
            variant="contained"
          >
            {t('Submit Another')}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Success;
