import { Typography } from '@material-ui/core';
import { LabelField as LabelFieldType } from '../../interfaces/FormFieldTypes';
import { memo } from 'react';

type Props = {
  field: LabelFieldType;
};

const LabelField = (props: Props) => {
  return (
    <Typography variant="body1" className="break-words">
      {props.field.config.title}
    </Typography>
  );
};

export default memo(LabelField);
