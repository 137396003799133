import axios from '../../lib/axios';
import { useContext, useMemo } from 'react';
import { AppContext } from '../context/AppState/AppContext';
import { useAppArgs } from './useAppArgs';
import { useAuthContext } from '../context/AuthState/AuthContext';

export const useNTFApi = () => {
  const { state: appState } = useContext(AppContext);
  const { fallbackDomain, tenant } = useAppArgs();
  const { workflowId, nodeId } = appState;
  const { state: authState } = useAuthContext();
  const authToken = authState.token;

  const ntfApi = useMemo(() => {
    console.log('DEBUG TEST VALUE: ', authToken);
    async function getUserPool() {
      const response = await axios.post(
        `https://${tenant.region}.ntf.${fallbackDomain}/userpool/all`,
        {
          slug: tenant.slug,
          region: tenant.region,
        },
      );
      console.log('user pool', response.data);
      return response.data;
    }

    async function startWorkflow() {
      console.log('DEBUG IN TEST VALUE: ', authToken);
      const response = await axios.post(
        `https://${tenant.region}.ntf.${fallbackDomain}/workflow/start`,
        {
          workflowId,
          nodeId,
          tenant: appState.tenant,
          dipa: false,
          hash: 'none',
          metadata: {
            stratus: true,
            tenant: appState.tenant,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      return response.data.executionArn;
    }

    async function checkWorkflowStatus(arn: string): Promise<{
      arn: string;
      token: string;
      callbackUrl: string;
      processing: boolean;
    }> {
      const response = await axios.post(
        `https://${tenant.region}.ntf.${fallbackDomain}/workflow/ui`,
        {
          arn,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      return response.data;
    }

    async function awaitWorkflowReady(arn: string): Promise<{
      arn: string;
      token: string;
      callbackUrl: string;
    }> {
      return new Promise(async (resolve, reject) => {
        let tries = 0;
        const intervalId = setInterval(async () => {
          try {
            tries++;
            if (tries > 5) {
              throw new Error('Reached maximum number of tries');
            }
            console.log('fetching workflow instance...');
            const instanceData = await checkWorkflowStatus(arn);
            if (instanceData.processing) {
              console.error('still processing..');
              return;
            }
            clearInterval(intervalId);
            return resolve(instanceData);
          } catch (err) {
            console.error(err);
            clearInterval(intervalId);
            return reject(err);
          }
        }, 1.5 * 1000);
      });
    }

    async function startWorkflowAndAwaitReady() {
      const arn = await startWorkflow();
      console.log('arn', arn);
      const instanceData = await awaitWorkflowReady(arn);
      return instanceData;
    }

    return {
      awaitWorkflowReady,
      checkWorkflowStatus,
      getUserPool,
      startWorkflow,
      startWorkflowAndAwaitReady,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, appState, nodeId, fallbackDomain, workflowId, authToken]);
  return ntfApi;
};
